import React from 'react'
import Header from './Components/Header'
import Body from './Components/Body'
import VercelFooter from './Components/VercelFooter'

function App() {
  return (
    <div>
      <Header />
      <Body />
      <VercelFooter />
    </div>
  )
}

export default App
